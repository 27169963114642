<template>
  <section class="content">
    <div
      class="modal show"
      tabindex="-1"
      role="dialog"
      ref="formFilter"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit="submitFilter">
            <div class="modal-header">
              <h5 class="modal-title">Filter Status</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <h2>
                  <span aria-hidden="true">&times;</span>
                </h2>
              </button>
            </div>
            <div class="modal-body">
              <div class="container">
                  <label class="control-label" v-if="sUmum">STATUS UMUM</label>
                <div class="row" v-if="sUmum">
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="pending"
                        @change="onChange($event)"
                        v-model="tracking.rpick"
                      />
                      <label class="form-check-label" for="pending">
                        Pending
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="prosesbuyer"
                        @change="onChange($event)"
                        v-model="tracking.delgo"
                      />
                      <label class="form-check-label" for="prosesbuyer">
                        Proses
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="delivered"
                        @change="onChange($event)"
                        v-model="tracking.delok"
                      />
                      <label class="form-check-label" for="delivered">
                        Terkirim
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="returshipper"
                        @change="onChange($event)"
                        v-model="tracking.retgo"
                      />
                      <label class="form-check-label" for="returshipper">
                        Proses Retur
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="returnok"
                        @change="onChange($event)"
                        v-model="tracking.retok"
                      />
                      <label class="form-check-label" for="returnok">
                        Retur Diserahkan
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="returned"
                        @change="onChange($event)"
                        v-model="tracking.retacc"
                      />
                      <label class="form-check-label" for="returned">
                        Retur Diterima
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="deler"
                        @change="onChange($event)"
                        v-model="tracking.deler"
                      />
                      <label class="form-check-label" for="deler">
                        Bermasalah
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="canc"
                        @change="onChange($event)"
                        v-model="tracking.canc"
                      />
                      <label class="form-check-label" for="canc">
                        Cancel
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="err"
                        @change="onChange($event)"
                        v-model="tracking.err"
                      />
                      <label class="form-check-label" for="err">
                        error
                      </label>
                    </div>
                  </div>
                </div>
                  <label class="control-label" v-if="sCod">STATUS COD</label>
                <div class="row" v-if="sCod">
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="cod" name="scod" @change="onChange($event)" v-model="filter.status_cod" value="cod" />
                        <label for="cod">COD</label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="noncod" name="scod" @change="onChange($event)" v-model="filter.status_cod" value="noncod" />
                        <label for="noncod">NON COD</label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="allstatus_cod" name="scod" @change="onChange($event)" v-model="filter.status_cod" value="allstatus_cod" />
                        <label for="allstatus_cod">SEMUA</label>
                    </div>
                  </div>
                </div>
                  <label class="control-label" v-if="sTicket">STATUS TICKET</label>
                <div class="row" v-if="sTicket">
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="open" name="sticket" @change="onChange($event)" v-model="filter.status_ticket" value="open" />
                        <label for="open">OPEN</label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="closed" name="sticket" @change="onChange($event)" v-model="filter.status_ticket" value="closed" />
                        <label for="closed">CLOSED</label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="allstatus_ticket" name="sticket" @change="onChange($event)" v-model="filter.status_ticket" value="allstatus_ticket" />
                        <label for="allstatus_ticket">SEMUA</label>
                    </div>
                  </div>
                </div>
                  <label class="control-label" v-if="sOther">LAINNYA</label>
                <div class="row" v-if="sOther">
                    <div class="form-group col-4">
                        <div class="icheck-material-orange icheck-inline form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="updated"
                            @change="onChange($event)"
                            v-model="filter.updated"/>
                        <label class="form-check-label" for="updated">
                            Perubahan Ongkir
                        </label>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <div class="icheck-material-orange icheck-inline form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="claim"
                            @change="onChange($event)"
                            v-model="filter.claim"/>
                        <label class="form-check-label" for="claim">
                            Paket Di Klaim
                        </label>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <div class="icheck-material-orange icheck-inline form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="notClaim"
                            @change="onChange($event)"
                            v-model="filter.notClaim"/>
                        <label class="form-check-label" for="notClaim">
                            Paket Tidak Di Klaim
                        </label>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Cari</button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Batal
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import { authFetch } from "@/libs/hxcore";
import $ from "jquery";
import "icheck-material";
export default {
  name: "Historyfilter",
  props: { 
    show: { default: false, type: Boolean },
    value: { type: Object },
    sUmum: { default: true, type: Boolean },
    sCod: { default: true, type: Boolean },
    sTicket: { default: true, type: Boolean },
    sOther: { default: true, type: Boolean }
  },
  data() {
    return {
      errors: [],
      tracking: {},
      filter: {},
    };
  },
  components: {},
  created() {
    this.filter = this.value;
  },
  methods: {
    onChange(event) {
      if (this.tracking.rpick == true){
        this.filter.rpick = 1;
      } else{
        this.filter.rpick = 0;
      }

      if (this.tracking.delgo == true){
        this.filter.delgo = 1;
      } else {
        this.filter.delgo = 0;
      }

      if (this.tracking.delok == true){
        this.filter.delok = 1;
      } else {
        this.filter.delok = 0;
      }

      if (this.tracking.retgo == true){
        this.filter.retgo = 1;
      } else {
        this.filter.retgo = 0;
      }

      if (this.tracking.retok == true){
        this.filter.retok = 1;
      } else {
        this.filter.retok = 0;
      }

      if (this.tracking.retacc == true){
        this.filter.retacc = 1;
      } else {
        this.filter.retacc = 0
      }

      if (this.tracking.deler == true){
        this.filter.deler = 1;
      } else {
        this.filter.deler = 0;
      }

      if (this.tracking.canc == true){
        this.filter.canc = 1;
      } else {
        this.filter.canc = 0;
      }

      if (this.tracking.err == true){
        this.filter.err = 1;
      } else {
        this.filter.err = 0;
      }

      let val = this.$emit("input", this.filter);
      // console.log("input: ", val);
    },
    showDialog(e) {
      $(this.refs.formFilter).modal(e ? "show" : "hide");
    },
    submitFilter: function (ev) {
      // this.table.api().ajax.reload();
        let trackings = this.tracking;
        let params = Object.keys(trackings);
        this.filter.status_tracking = params.filter(function(key) {
            return trackings[key] == true;
        });

        let val = this.$emit("change", this.filter);
        $(this.$refs.formFilter).modal("hide");
        ev.preventDefault();
    },
  },
  mounted() {
    if (this.tracking.rpick || this.filter.rpick){
          $("#pending").prop("checked", true);
    } 
    if (this.tracking.delgo || this.filter.delgo){
          $("#prosesbuyer").prop("checked", true);
    }
    if (this.tracking.delok || this.filter.delok){
          $("#delivered").prop("checked", true);
    }
    if (this.tracking.retgo || this.filter.retgo){
          $("#returshipper").prop("checked", true);
    }
    if (this.tracking.retok || this.filter.retok){
          $("#returnok").prop("checked", true);
    }
    if (this.tracking.retacc || this.filter.retacc){
          $("#returned").prop("checked", true);
    }
    if (this.tracking.deler || this.filter.deler){
          $("#deler").prop("checked", true);
    }
    if (this.tracking.canc || this.filter.canc){
          $("#canc").prop("checked", true);
    }
    if (this.tracking.err || this.filter.err){
          $("#err").prop("checked", true);
    }
  },
  watch: {
    show: function (val, old) {
      $(this.$refs.formFilter).modal("show");
    },
    value: function (val, old) {
      this.filter = val;
    },
  },
};
</script>
<style scoped>
</style>